:root {
  --color-schema-primary-base: #ffff00;
  --color-schema-primary-light: #ffff33;
  --color-schema-primary-dark: #cccc00;
  --color-schema-primary-text: #f5f5f5;
  --color-schema-primary-text-light: #f5f5f5;
  --color-schema-primary-text-dark: #212121;
  --color-schema-secondary-base: #707372;
  --color-schema-secondary-light: #898d8c;
  --color-schema-secondary-dark: #575958;
  --color-schema-secondary-text: #f5f5f5;
  --color-schema-secondary-text-light: #f5f5f5;
  --color-schema-secondary-text-dark: #212121;
  --color-schema-accent-base: #ff5722;
  --color-schema-accent-light: #ff7e55;
  --color-schema-accent-dark: #ee3900;
  --color-schema-accent-text: #f5f5f5;
  --color-schema-accent-text-light: #f5f5f5;
  --color-schema-accent-text-dark: #212121;
  --color-schema-success-base: #006400;
  --color-schema-success-light: #009700;
  --color-schema-success-dark: #003100;
  --color-schema-success-text: #f5f5f5;
  --color-schema-success-text-light: #f5f5f5;
  --color-schema-success-text-dark: #212121;
  --color-schema-warning-base: #ffc107;
  --color-schema-warning-light: #ffce3a;
  --color-schema-warning-dark: #d39e00;
  --color-schema-warning-text: #f5f5f5;
  --color-schema-warning-text-light: #f5f5f5;
  --color-schema-warning-text-dark: #212121;
  --color-schema-danger-base: #f44336;
  --color-schema-danger-light: #f77066;
  --color-schema-danger-dark: #ea1c0d;
  --color-schema-danger-text: #f5f5f5;
  --color-schema-danger-text-light: #f5f5f5;
  --color-schema-danger-text-dark: #212121;
  --color-schema-info-base: #03a9f4;
  --color-schema-info-light: #2ebcfc;
  --color-schema-info-dark: #0286c2;
  --color-schema-info-text: #f5f5f5;
  --color-schema-info-text-light: #f5f5f5;
  --color-schema-info-text-dark: #212121;
  --color-schema-light-base: #f5f5f5;
  --color-schema-light-light: white;
  --color-schema-light-dark: gainsboro;
  --color-schema-light-text: #212121;
  --color-schema-light-text-light: #f5f5f5;
  --color-schema-light-text-dark: #212121;
  --color-schema-dark-base: #212121;
  --color-schema-dark-light: #3b3b3b;
  --color-schema-dark-dark: #080808;
  --color-schema-dark-text: #f5f5f5;
  --color-schema-dark-text-light: #f5f5f5;
  --color-schema-dark-text-dark: #212121;
  --color-schema-card-base: #ffffff;
  --color-schema-card-light: white;
  --color-schema-card-dark: #e6e6e6;
  --color-schema-card-text: #212121;
  --color-schema-card-text-light: #f5f5f5;
  --color-schema-card-text-dark: #212121;
  --shades-blue_400: #4abeff;
  --shades-blue_450: #047af1;
  --shades-blue_500: #4d6edb;
  --shades-red_300: #ffdede;
  --shades-red_400: #ff6f71;
  --shades-red_500: #ff2a27;
  --shades-red_600: #ca080b;
  --shades-orange_300: #ffebd0;
  --shades-orange_400: #ffb042;
  --shades-orange_500: #e4982d;
  --shades-orange_600: #9f5c00;
  --shades-cyan_400: #d1f7f6;
  --shades-cyan_500: #90eae9;
  --shades-purple_400: #f0ecfc;
  --shades-purple_450: #d8bfff;
  --shades-purple_500: #b476ff;
  --shades-purple_600: #2f0072;
  --shades-gray_200: #f2f2f2;
  --shades-gray_300: #dfdede;
  --shades-gray_350: #d4d4d4;
  --shades-gray_400: #939191;
  --shades-gray_500: #707372;
  --shades-gray_600: #5a5a5a;
  --shades-gray_700: #363636;
  --shades-gray_800: #262626;
  --shades-green_200: #e6f2eb;
  --shades-green_300: #69d053;
  --shades-green_400: #33a228;
  --shades-green_500: darkgreen;
  --shades-green_600: #005607;
  --shades-green_700: #00480c;
  --shades-green_800: #003a0f;
  --shades-yellow_500: yellow;
  --shades-white: white;
  --shades-black: black;
  --shades-trasparent: rgba(255, 255, 255, 0);
  --shades-card: white;
  --bz-color-brand-accent-cold-background: #d1f7f6;
  --bz-color-brand-accent-cold-basis: #9cfffd;
  --bz-color-brand-accent-cold-gradient: linear-gradient(180deg, #9cfffdff 0%, #7ce5e3ff 100%);
  --bz-color-brand-accent-cold-text: #007a78;
  --bz-color-brand-accent-exciting-background: #ffdede;
  --bz-color-brand-accent-exciting-basis: #ff6f71;
  --bz-color-brand-accent-exciting-gradient: linear-gradient(0deg, #de5052ff 0%, #ff6f71ff 100%);
  --bz-color-brand-accent-exciting-text: #c9201d;
  --bz-color-brand-accent-neutral-background: #fbf9ff;
  --bz-color-brand-accent-neutral-basis: #f0ecfc;
  --bz-color-brand-accent-neutral-gradient: linear-gradient(0deg, #dcd4f4ff 0%, #f0ecfcff 100%);
  --bz-color-brand-accent-neutral-text: #615d6d;
  --bz-color-brand-accent-warm-background: #ffebd0;
  --bz-color-brand-accent-warm-basis: #ffebd0;
  --bz-color-brand-accent-warm-gradient: linear-gradient(180deg, #ffb042ff 0%, #e4982dff 100%);
  --bz-color-brand-accent-warm-text: #9f5c00;
  --bz-color-brand-primary-background: #fffbe0;
  --bz-color-brand-primary-basis: yellow;
  --bz-color-brand-primary-gradient: linear-gradient(180deg, #fefe55 0%, #f2f209 100%);
  --bz-color-brand-primary-text: #807500;
  --bz-color-alternative-comfort: #d6bc9a;
  --bz-color-alternative-confidence: #4abeff;
  --bz-color-alternative-creativity: #b476ff;
  --bz-color-alternative-delicate: #efacbc;
  --bz-color-alternative-healing: #81ffad;
  --bz-color-alternative-imagination: #c9abff;
  --bz-color-alternative-lucky: #d3ff50;
  --bz-color-alternative-sweet: #ffa4ee;
  --bz-color-semantic-error-background: #ffdede;
  --bz-color-semantic-error-basis: #e4423f;
  --bz-color-semantic-error-text: #c9201d;
  --bz-color-semantic-info-background: #e9f1ff;
  --bz-color-semantic-info-basis: #047af1;
  --bz-color-semantic-info-text: #0363c4;
  --bz-color-semantic-neutral-background: #f5f2fd;
  --bz-color-semantic-neutral-basis: #7c6bae;
  --bz-color-semantic-neutral-text: #625194;
  --bz-color-semantic-success-background: #e6f2eb;
  --bz-color-semantic-success-basis: #1f8e4a;
  --bz-color-semantic-success-text: #176d38;
  --bz-color-semantic-warning-background: #ffebd0;
  --bz-color-semantic-warning-basis: #ffb042;
  --bz-color-semantic-warning-text: #9f5c00;
  --bz-color-neutral-0: white;
  --bz-color-neutral-10: #fafafa;
  --bz-color-neutral-100: black;
  --bz-color-neutral-20: #f2f2f2;
  --bz-color-neutral-30: #dedede;
  --bz-color-neutral-40: #a7a7a7;
  --bz-color-neutral-50: gray;
  --bz-color-neutral-60: #585858;
  --bz-color-neutral-70: #424242;
  --bz-color-neutral-80: #1a1a1a;
  --bz-color-neutral-90: #0a0a0a;
  --bz-color-interface-datavis-1: #b070ff;
  --bz-color-interface-datavis-10: #654c29;
  --bz-color-interface-datavis-11: #ff6105;
  --bz-color-interface-datavis-12: #0f558a;
  --bz-color-interface-datavis-13: #949400;
  --bz-color-interface-datavis-14: #006664;
  --bz-color-interface-datavis-2: #3b13aa;
  --bz-color-interface-datavis-3: #3b13aa;
  --bz-color-interface-datavis-4: #940079;
  --bz-color-interface-datavis-5: #d17a00;
  --bz-color-interface-datavis-6: #591223;
  --bz-color-interface-datavis-7: #591223;
  --bz-color-interface-datavis-8: #2e3d00;
  --bz-color-interface-datavis-9: #00a83b;
  --bz-color-interface-datavis-mono-cold-10: #dffffe;
  --bz-color-interface-datavis-mono-cold-100: #006462;
  --bz-color-interface-datavis-mono-cold-20: #c8fffe;
  --bz-color-interface-datavis-mono-cold-30: #9bfffd;
  --bz-color-interface-datavis-mono-cold-40: #6ffffc;
  --bz-color-interface-datavis-mono-cold-50: #6ffffc;
  --bz-color-interface-datavis-mono-cold-60: #16fffa;
  --bz-color-interface-datavis-mono-cold-70: #00e9e4;
  --bz-color-interface-datavis-mono-cold-80: #00bcb9;
  --bz-color-interface-datavis-mono-cold-90: #00908d;
  --bz-color-interface-datavis-mono-confidence-10: #dff4ff;
  --bz-color-interface-datavis-mono-confidence-100: #004064;
  --bz-color-interface-datavis-mono-confidence-20: #c8ebff;
  --bz-color-interface-datavis-mono-confidence-30: #9bdbff;
  --bz-color-interface-datavis-mono-confidence-40: #6fcbff;
  --bz-color-interface-datavis-mono-confidence-50: #43bbff;
  --bz-color-interface-datavis-mono-confidence-60: #16abff;
  --bz-color-interface-datavis-mono-confidence-70: #0095e9;
  --bz-color-interface-datavis-mono-confidence-80: #0079bc;
  --bz-color-interface-datavis-mono-confidence-90: #005c90;
  --bz-color-interface-datavis-mono-creativity-10: #eedfff;
  --bz-color-interface-datavis-mono-creativity-100: #2d0064;
  --bz-color-interface-datavis-mono-creativity-20: #e1c8ff;
  --bz-color-interface-datavis-mono-creativity-30: #c89bff;
  --bz-color-interface-datavis-mono-creativity-40: #b06fff;
  --bz-color-interface-datavis-mono-creativity-50: #9843ff;
  --bz-color-interface-datavis-mono-creativity-60: #8016ff;
  --bz-color-interface-datavis-mono-creativity-70: #6900e9;
  --bz-color-interface-datavis-mono-creativity-80: #5500bc;
  --bz-color-interface-datavis-mono-creativity-90: #410090;
  --bz-color-interface-datavis-mono-exciting-10: #ffdfe0;
  --bz-color-interface-datavis-mono-exciting-100: #640001;
  --bz-color-interface-datavis-mono-exciting-20: #ffc8c8;
  --bz-color-interface-datavis-mono-exciting-30: #ff9b9d;
  --bz-color-interface-datavis-mono-exciting-40: #ff6f71;
  --bz-color-interface-datavis-mono-exciting-50: #ff4345;
  --bz-color-interface-datavis-mono-exciting-60: #ff1619;
  --bz-color-interface-datavis-mono-exciting-70: #e90003;
  --bz-color-interface-datavis-mono-exciting-80: #bc0003;
  --bz-color-interface-datavis-mono-exciting-90: #900002;
  --bz-color-interface-datavis-mono-grow-10: #f5fef9;
  --bz-color-interface-datavis-mono-grow-100: #03341a;
  --bz-color-interface-datavis-mono-grow-20: #e1fdee;
  --bz-color-interface-datavis-mono-grow-30: #cbfce2;
  --bz-color-interface-datavis-mono-grow-40: #a1f9ca;
  --bz-color-interface-datavis-mono-grow-50: #4ef39b;
  --bz-color-interface-datavis-mono-grow-60: #24f184;
  --bz-color-interface-datavis-mono-grow-70: #0cb159;
  --bz-color-interface-datavis-mono-grow-80: #098744;
  --bz-color-interface-datavis-mono-grow-90: #065e2f;
  --bz-color-interface-datavis-mono-primary-10: #ffffdf;
  --bz-color-interface-datavis-mono-primary-100: #4e4e00;
  --bz-color-interface-datavis-mono-primary-20: #ffffb1;
  --bz-color-interface-datavis-mono-primary-30: #ffff85;
  --bz-color-interface-datavis-mono-primary-40: #ffff59;
  --bz-color-interface-datavis-mono-primary-50: #ffff2c;
  --bz-color-interface-datavis-mono-primary-60: yellow;
  --bz-color-interface-datavis-mono-primary-70: #d3d300;
  --bz-color-interface-datavis-mono-primary-80: #a6a600;
  --bz-color-interface-datavis-mono-primary-90: #7a7a00;
  --bz-color-interface-datavis-mono-warm-10: #fff2df;
  --bz-color-interface-datavis-mono-warm-100: #643a00;
  --bz-color-interface-datavis-mono-warm-20: #ffe8c8;
  --bz-color-interface-datavis-mono-warm-30: #ffd59b;
  --bz-color-interface-datavis-mono-warm-40: #ffc36f;
  --bz-color-interface-datavis-mono-warm-50: #ffb043;
  --bz-color-interface-datavis-mono-warm-60: #ff9e16;
  --bz-color-interface-datavis-mono-warm-70: 0 #ff9e16;
  --bz-color-interface-datavis-mono-warm-80: #bc6e00;
  --bz-color-interface-datavis-mono-warm-90: #905400;
  --bz-color-interface-foreground-label-disabled: rgba(255, 255, 255, 0.6);
  --bz-color-interface-foreground-label-primary: rgba(255, 255, 255, 0.95);
  --bz-color-interface-foreground-label-secondary: rgba(255, 255, 255, 0.6);
  --bz-color-interface-foreground-surface-primary: rgba(255, 255, 255, 0.1);
  --bz-color-interface-foreground-surface-secondary: rgba(255, 255, 255, 0.2);
  --bz-color-interface-label-disabled: rgba(0, 0, 0, 0.55);
  --bz-color-interface-label-primary: rgba(0, 0, 0, 0.9);
  --bz-color-interface-label-secondary: rgba(0, 0, 0, 0.55);
  --bz-color-interface-surface-clear: rgba(0, 0, 0, 0);
  --bz-color-interface-surface-primary: rgba(0, 0, 0, 0.05);
  --bz-color-interface-surface-secondary: rgba(0, 0, 0, 0.15);
  --bz-color-subbrand-care-background: #ffdede;
  --bz-color-subbrand-care-basis: #ff6f71;
  --bz-color-subbrand-care-gradient: linear-gradient(180deg, #ff6f71 0%, #de5052 100%);
  --bz-color-subbrand-care-text: #c9201d;
  --bz-color-subbrand-customer-background: #ffffbf;
  --bz-color-subbrand-customer-basis: yellow;
  --bz-color-subbrand-customer-gradient: linear-gradient(180deg, #fefe55 0%, #f2f209 100%);
  --bz-color-subbrand-customer-text: #807500;
  --bz-color-subbrand-deliver-background: #ffebd0;
  --bz-color-subbrand-deliver-basis: #ffb042;
  --bz-color-subbrand-deliver-gradient: linear-gradient(180deg, #ffb042 0%, #e4982d 100%);
  --bz-color-subbrand-deliver-text: #9f5c00;
  --bz-color-subbrand-engage-background: #e5f3a9;
  --bz-color-subbrand-engage-basis: #bee228;
  --bz-color-subbrand-engage-gradient: linear-gradient(180deg, #abcb24 0%, #d2eb69 100%);
  --bz-color-subbrand-engage-text: #5f7114;
  --bz-color-subbrand-force-primary-background: gray;
  --bz-color-subbrand-force-primary-basis: black;
  --bz-color-subbrand-force-primary-gradient: linear-gradient(180deg, #454545 0%, #000000 100%);
  --bz-color-subbrand-force-primary-text: black;
  --bz-color-subbrand-force-secondary-background: #ffffbf;
  --bz-color-subbrand-force-secondary-basis: yellow;
  --bz-color-subbrand-force-secondary-gradient: linear-gradient(180deg, #fefe55 0%, #f2f209 100%);
  --bz-color-subbrand-force-secondary-text: #807500;
  --bz-color-subbrand-grow-background: #cffde5;
  --bz-color-subbrand-grow-basis: #1af07e;
  --bz-color-subbrand-grow-gradient: linear-gradient(180deg, #3df280 0%, #35d073 100%);
  --bz-color-subbrand-grow-text: #1a7940;
  --bz-color-subbrand-lift-background: #d1f7f6;
  --bz-color-subbrand-lift-basis: #00d7d5;
  --bz-color-subbrand-lift-gradient: linear-gradient(180deg, #00d7d5 0%, #00c1bf 100%);
  --bz-color-subbrand-lift-text: #007a78;
  --bz-color-subbrand-partner-background: #eee5ff;
  --bz-color-subbrand-partner-basis: #c9abff;
  --bz-color-subbrand-partner-gradient: linear-gradient(180deg, #bb99ff 0%, #a070ff 100%);
  --bz-color-subbrand-partner-text: #7835fd;
  --bz-color-subbrand-tada-primary-background: #d8bfff;
  --bz-color-subbrand-tada-primary-basis: #a47be8;
  --bz-color-subbrand-tada-primary-gradient: linear-gradient(180deg, #a47be8 0%, #2f0072 100%);
  --bz-color-subbrand-tada-primary-text: #2f0072;
  --bz-color-subbrand-tada-secondary-background: #fffb83;
  --bz-color-subbrand-tada-secondary-basis: #f5e003;
  --bz-color-subbrand-tada-secondary-gradient: linear-gradient(180deg, #f5e003 0%, #a27d00 100%);
  --bz-color-subbrand-tada-secondary-text: #a27d00;
  --bz-opacity-opaque: 1;
  --bz-opacity-transparent: 0;
  --bz-opacity-intense: 0.4;
  --bz-opacity-light: 0.16;
  --bz-opacity-medium: 0.32;
  --bz-opacity-semi: 0.08;
  --bz-elevation-025: 0px 0px 0px 1px rgba(0, 0, 0, var(--bz-opacity-light));
  --bz-elevation-1: 0px 2px 4px 0px rgba(0, 0, 0, var(--bz-opacity-semi));
  --bz-elevation-2: 0px 4px 8px 0px rgba(0, 0, 0, var(--bz-opacity-semi));
  --bz-elevation-4: 0px 4px 16px 0px rgba(0, 0, 0, var(--bz-opacity-light));
  --bz-elevation-6: 0px 8px 24px 0px rgba(0, 0, 0, var(--bz-opacity-light));
  --bz-elevation-8: 0px 16px 32px 0px rgba(0, 0, 0, var(--bz-opacity-light));
  --bz-font-family-heading: Barlow, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  --bz-font-family-paragraph: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  --bz-font-line-height-10: 56px;
  --bz-font-line-height-11: 64px;
  --bz-font-line-height-12: 72px;
  --bz-font-line-height-13: 80px;
  --bz-font-line-height-3: 16px;
  --bz-font-line-height-4: 18px;
  --bz-font-line-height-5: 20px;
  --bz-font-line-height-6: 24px;
  --bz-font-line-height-7: 32px;
  --bz-font-line-height-8: 40px;
  --bz-font-line-height-9: 48px;
  --bz-font-size-1: 12px;
  --bz-font-size-10: 56px;
  --bz-font-size-11: 64px;
  --bz-font-size-12: 72px;
  --bz-font-size-2: 14px;
  --bz-font-size-3: 16px;
  --bz-font-size-4: 18px;
  --bz-font-size-5: 20px;
  --bz-font-size-6: 24px;
  --bz-font-size-7: 32px;
  --bz-font-size-8: 40px;
  --bz-font-size-9: 48px;
  --bz-font-weight-medium: 500;
  --bz-font-weight-normal: 400;
  --bz-font-weight-semibold: 600;
  --bz-space-25: 1px;
  --bz-space-5: 2px;
  --bz-space-1: 4px;
  --bz-space-10: 40px;
  --bz-space-12: 48px;
  --bz-space-14: 56px;
  --bz-space-16: 64px;
  --bz-space-2: 8px;
  --bz-space-20: 80px;
  --bz-space-24: 96px;
  --bz-space-28: 112px;
  --bz-space-4: 16px;
  --bz-space-6: 24px;
  --bz-space-8: 32px;
  --bz-radius-1: 4px;
  --bz-radius-2: 8px;
  --bz-radius-4: 16px;
  --bz-radius-6: 24px;
  --bz-radius-8: 32px;
  --bz-radius-full: 9999px;
  --bz-radius-none: 0px;
  --bz-border-hairline: 1px;
  --bz-border-heavy: 8px;
  --bz-border-none: 0px;
  --bz-border-thick: 4px;
  --bz-border-thin: 2px;
  --fonts-common: Work Sans;
  --fonts-title: Barlow;
  --fonts-text: Work Sans;
  --main-values-padding: 16px;
  --main-values-margin: 16px;
  --main-values-disabled-color: #bdbdbd;
  --main-values-min-width: 360px;
  --main-values-max-width: 1440px;
  --main-values-border-radius: 1rem;
  --buttons-flat-padding: 8px;
  --buttons-flat-border-radius: 1rem;
  --buttons-flat-circle-size: 36px;
  --buttons-flat-circle-padding: 16px;
  --buttons-outline-padding: 8px;
  --buttons-outline-border-size: 2px;
  --buttons-outline-border-radius: 1rem;
  --forms-select-padding: 8px;
  --forms-select-border-radius: 4px;
  --forms-select-font-size: 1rem;
  --forms-select-border-size: 2px;
  --forms-select-border-style: solid;
  --forms-input-border-radius: 4px;
  --carousel-margin-top: 16px;
  --carousel-margin-bottom: 32px;
  --carousel-item-gap: -1%;
  --carousel-item-percent-size: 90%;
  --carousel-item-border-radius: 1rem;
  --carousel-item-web-percent-size: 102%;
  --carousel-item-web-gap: -3%;
  --app-bar-height: 56px;
  --app-bar-padding: 16px;
  --app-bar-background-color: white;
  --app-bar-color: #212121;
  --app-bar-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  --app-bar-z-index: 99;
  --app-bar-title-margin-right: 16px;
  --card-border-radius: 1rem;
  --card-padding: 16px;
  --card-margin: 8px;
  --card-color: var(--contrast-minimum);
  --card-text-color: var(--contrast-high);
  --quantity-add-buttons-width: 115px;
  --quantity-add-buttons-border-radius: 2rem;
  --quantity-add-buttons-border: 1px solid;
  --quantity-add-buttons-padding: 2px;
  --ink-well-padding: 4px;
  --title-font-size: 30px;
  --title-font-weight: 700;
  --title-line-height: 1.2;
  --title-margin-top: 1em;
  --title-margin-bottom: 1em;
  --expansion-tile-transition-delay: 0.5s;
  --expansion-tile-padding-horizontal: 16px;
  --expansion-tile-padding-vertical: 8px;
  --expansion-tile-border-size: 1px;
  --expansion-tile-border-style: solid;
  --expansion-tile-border-color: #ccc;
  --expansion-tile-transition-timing-function: ease;
}
@media (prefers-color-scheme: light) {
  :root {
    --contrast-minimum: var(--color-schema-light-light);
    --contrast-low: var(--color-schema-light-base);
    --contrast-mid: var(--color-schema-light-dark);
    --contrast-high: var(--color-schema-dark-base);
    --contrast-maximum: var(--color-schema-dark-dark);
  }
}
@media (prefers-color-scheme: dark) {
  :root {
    --contrast-minimum: var(--color-schema-light-light);
    --contrast-low: var(--color-schema-light-base);
    --contrast-mid: var(--color-schema-light-dark);
    --contrast-high: var(--color-schema-dark-base);
    --contrast-maximum: var(--color-schema-dark-dark);
  }
}