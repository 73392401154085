@import '../core/functions.scss';
@import './theme.base';

@mixin generator($theme) {
  :root {
    @each $key, $value in map-flatten($theme) {
      #{$key}: #{$value};
    }

    @media (prefers-color-scheme: light) {
      --contrast-minimum: var(--color-schema-light-light);
      --contrast-low: var(--color-schema-light-base);
      --contrast-mid: var(--color-schema-light-dark);
      --contrast-high: var(--color-schema-dark-base);
      --contrast-maximum: var(--color-schema-dark-dark);
    }

    @media (prefers-color-scheme: dark) {
      --contrast-minimum: var(--color-schema-light-light);
      --contrast-low: var(--color-schema-light-base);
      --contrast-mid: var(--color-schema-light-dark);
      --contrast-high: var(--color-schema-dark-base);
      --contrast-maximum: var(--color-schema-dark-dark);
    }
  }
}
