/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'MaterialCommunityIcons';
  src: url('fonts/MaterialCommunityIcons.ttf') format('truetype');
}

@font-face {
  font-family: 'HexaIcons';
  src: url('fonts/HexaIcons.ttf') format('truetype');
}

@font-face {
  font-family: 'MaterialIconsOutlinedRegular';
  src: url('fonts/MaterialIconsOutlined-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Twemoji Country Flags';
  src: url('https://cdn.jsdelivr.net/npm/country-flag-emoji-polyfill@0.1/dist/TwemojiCountryFlags.woff2') format('woff2');
  unicode-range: 'U+1F1E6-1F1FF', 'U+1F3F4', 'U+E0062-E0063', 'U+E0065', 'U+E0067',
    'U+E006C', 'U+E006E', 'U+E0073-E0074', 'U+E0077', 'U+E007F';
  font-display: swap;
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: Work Sans, sans-serif;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: var(--fonts-text), Work Sans, sans-serif;
}

input,
select,
textarea,
button,
p,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6,
nav,
a {
  font-family: var(--fonts-text), Work Sans, sans-serif;
}